.result-table-th {
	border: 1px;
	border-collapse: collapse;
	table-layout: auto;
	width: 100%;
	-webkit-print-color-adjust: exact;
	-moz-print-color-adjust: exact;
	-ms-print-color-adjust: exact;
	print-color-adjust: exact;
}

/* .result-table-th thead {
  background-color: var(--primary);
} */

.result-table-th thead td {
	/* color: var(--white-color); */
	font-size: 1.3rem;
}

.course-fields {
	max-height: 200px;
	min-height: 200px;
}

.course-fields-name {
	max-width: 241px !important;
	max-height: 241px !important;
	width: 241px !important;
}

.result-table-th tbody td,
.grade_span {
	font-size: 1.3rem;
}

.red_color_text {
	color: var(--danger);
}

.result-table-th tr {
	border: 1px solid var(--border-stoke) !important;
}

.result-table-th td {
	border: 1px solid var(--border-stoke) !important;
	padding: 5px;
}

.vertical-td {
	writing-mode: vertical-lr;
	text-orientation: mixed;
	transform: rotate(180deg);
}

.td-align-center {
	text-align: center;
}

.font-size-for-out {
	font-size: 1rem;
}

.outstanding_td_style {
	font-size: 1rem!important;
}