.container {
	background-position: top;
	background-repeat: no-repeat;
	min-height: 100vh;
	background-size: cover;
}

.sectionTextAndImageContainer {
	position: relative;
	max-width: 1440px;
	margin: 0 auto;
	padding: 50px 20px 100px 20px;
}

.dots {
	display: none;
}

.sectionTextContainer {
	margin-bottom: 340px;
}

.sectionTextContainer h1,
.sectionTextContainer p {
	color: var(--text-color) !important;
}

.sectionsContainer section:nth-of-type(1) > div * {
	text-align: start !important;
	margin-bottom: 18px;
}

.notice {
	display: inline-block;
	background-color: var(--primary);
	color: var(--white-color);
	padding: 5px 15px 5px 12px;
	border-radius: 100px;
	animation: tilt-shaking 0.35s infinite;
	animation-iteration-count: 7;
	font-size: 0.75rem;
}

.notice span {
	display: inline-block;
	border-radius: 100px;
	background-color: var(--light-red);
	color: var(--white-color);
	padding: 5px 10px;
	margin-right: 10px;
	font-size: 0.75rem;
}

.sectionsContainer section:nth-of-type(1) h1 {
	line-height: 1.36rem;
}

.sectionsContainer section:nth-of-type(1) p {
	font-weight: 300;
}

.homeImage {
	position: absolute;
	height: 460px;
	right: 0;
	left: 0;
	bottom: -60px;
}

.homeImage::before {
	position: absolute;
	content: "";
	right: 0;
	height: 100%;
	width: 72%;
	z-index: 200;
	background-color: var(--darkPrimary);
}

.homeImage img {
	position: relative;
	object-fit: cover;
	width: 98.5%;
	height: 100%;
	z-index: 200;
}

.cardsContainer {
	position: relative;
	margin-top: 20px;
	padding: 10px;
	width: 100%;
	background-color: var(--neutral-20);
	border-radius: 12px;
	z-index: 300;
	margin: 0 auto !important;
}

.card {
	display: inline-block;
	background: var(--white-color);
	border-radius: 6px;
	padding: 40px;
	width: 100%;
	height: 300px;
}

.card > * {
	margin-bottom: 15px;
}

.card p {
	color: var(--text-neutral);
	font-size: 1rem;
	text-align: left;
}

.cardLinks {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 30px;
}

.cardLinks a {
	text-decoration: none;
	color: var(--primary);
	font-weight: 500;
}

.cardLinks a:hover {
	text-decoration: underline;
	color: var(--text-teal);
}

@keyframes tilt-shaking {
	0% {
		transform: translate(0, 0) rotate(0deg);
	}

	25% {
		transform: translate(5px, 5px) rotate(5deg);
	}

	50% {
		transform: translate(0, 0) rotate(0eg);
	}

	75% {
		transform: translate(-5px, 5px) rotate(-5deg);
	}

	100% {
		transform: translate(0, 0) rotate(0deg);
	}
}

@media (min-width: 768px) {
	.notice,
	.notice span {
		font-size: 1rem;
	}

	.sectionsContainer section:nth-of-type(1) > div * {
		text-align: start !important;
	}

	.sectionsContainer section:nth-of-type(1) h1 {
		line-height: 50px;
		font-size: 3rem !important;
	}

	.sectionsContainer section:nth-of-type(1) p {
		text-align: start !important;
		line-height: 25px;
	}

	.cardsContainer {
		margin-top: 60px;
		padding: 20px;
		width: calc(100% - 40px);
	}

	.card p {
		color: var(--text-neutral);
		font-size: 0.85rem;
	}
}

@media (min-width: 1280px) {
	.sectionsContainer {
		padding-top: 50px;
		margin: 0 auto;
	}

	.sectionsContainer section:nth-of-type(1) h1 {
		font-size: 4.29rem !important;
		line-height: 66px;
	}

	.sectionsContainer section:nth-of-type(1) p {
		font-size: 1.29rem;
		line-height: 26px;
		text-align: start !important;
	}

	.sectionTextAndImageContainer {
		padding: 130px 140px 10px 140px;
	}

	.dots {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 75px;
	}
	.sectionTextContainer {
		margin-bottom: 240px;
	}

	.homeImage {
		height: 780px;
		top: 0;
		right: 0;
		left: auto;
	}
	.cardsContainer {
		max-width: 1300px !important;
		width: calc(100% - 128px);
	}
}

@media (max-width: 768px) {
	.description br {
		display: none;
	}

	.sectionTextContainer h1 {
		font-size: 2.5rem;
		width: 100%;
		line-height: 1 !important;
	}
	.sectionTextContainer p {
		line-height: 1.2 !important;
	}
}
