.login_label {
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
}

.login_inner_wrapper {
	background-color: var(--darkPrimary);
	position: relative;
}

.login_inner_wrapper section{
	width: 60%;
	height: 100%;
	margin: 0 auto;

}


.login_inner_wrapper h5{
	color: white !important;
	line-height: 1;
	font-size: 2.5vw;

}

.login_inner_wrapper p{
	line-height: 1.5;
	margin-top: 1rem;
}

.lines {
	position: absolute;
	top: 0;
	left: 0;
}
.lines img:last-child{
	transform: scaleY(-1);
	top: 0;
	left: 0;
	object-fit: cover;
}
.lines img{
	position: absolute;
	width: 95%;
	height: auto;
}
.lines2 img{
	position: absolute;
	width: 94%;
	height: auto;
}

.lines2 {
	position: absolute;
	width: 100%;
	bottom: 26%;
	left: 0;
}
.lines2 img:first-child{
	transform: scaleY(-1);
	top: 40px;
	left: 0;
	object-fit: cover;
}

.description {
	color: white;
}

.login_img {
	height: 100vh;
	width: 100%;
	object-fit: cover;
}

.link_class {
	font-size: 1rem;
	background-color: transparent;
	color: var(--primary);
	font-weight: 450;
	text-decoration: none !important;
}

.link_class:hover {
	text-decoration: underline !important;
}

.link_class_forgot {
	font-size: 1rem;
	background-color: transparent;
	color: var(--text-color);
	font-weight: 450;
	text-decoration: none !important;
}

.login_content h3 {
	font-weight: bold;
	font-size: 1.71rem;
	line-height: 32px;
	margin-bottom: 10px;
}

.login_content p {
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 20px;
	margin: 0;
}

.login_row {
	position: relative;
}

@media screen and (max-width: 769px) {
	.login_inner_wrapper {
		display: none;
	}

	.login_content {
		height: 100vh;
		width: 100vw;
	}

	.login_img-wrapper {
		width: 100%;
	}

	.login_row {
		margin: 0px;
	}
}
