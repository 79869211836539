.landing_card_container {
	width: fit-content;
	padding: 45.33px;
	background: var(--white-color);
	border-radius: 6px;
	border: 1px solid rgba(0,0,0,0.1);
}

.landing_card_title {
	margin-top: 37.33px;
	font-size: 1.36rem;
	font-weight: 700;
	color: var(--alt-text-color-tint);
}

.landing_card_info {
	margin-top: 16px;
	font-size: 1rem;
	line-height: 20.02px;
	color: var(--light-text-color-2);
	margin-bottom: 32px;
}

.landing_card_link {
	color: var(--primary);
	font-size: 1rem;
	cursor: pointer;
	text-decoration: none;
	display: block;
}
